@font-face {
	font-family: 'Aeonik';
	src: url('../fonts/Aeonik-Regular.eot');
	src: url('../fonts/Aeonik-Regular.woff2') format('woff2'),
		url('../fonts/Aeonik-Regular.woff') format('woff'),
		url('../fonts/Aeonik-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Aeonik-Medium';
	src: url('../fonts/Aeonik-Medium.eot');
	src: url('../fonts/Aeonik-Medium.woff2') format('woff2'),
		url('../fonts/Aeonik-Medium.woff') format('woff'),
		url('../fonts/Aeonik-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Aeonik-Bold';
	src: url('../fonts/Aeonik-Bold.eot');
	src: url('../fonts/Aeonik-Bold.woff2') format('woff2'),
		url('../fonts/Aeonik-Bold.woff') format('woff'),
		url('../fonts/Aeonik-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Aeonik-Black';
	src: url('../fonts/Aeonik-Black.eot');
	src: url('../fonts/Aeonik-Black.woff2') format('woff2'),
		url('../fonts/Aeonik-Black.woff') format('woff'),
		url('../fonts/Aeonik-Black.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
