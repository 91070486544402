@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts.css';
body {
	font-family: 'Aeonik', sans-serif;
	width: 100vw;
	height: 100vh;
	background-color: #f7f9fb;

	overflow: hidden;
}

.custom-editor * { font-family: 'Arial'; }

.ck-editor__editable_inline {
	padding: 0 30px !important;
}

.custom-editor .ck-editor__editable_inline {
	min-height: 300px; /* Ajuste a altura conforme necessário */
}

.ck .ck-button.ck-on .ck-icon {
	color: inherit !important;
}
.ck .ck-button.ck-off .ck-icon {
	color: inherit !important;
}

/* Garanta que a cor do texto em negrito seja herdada */
.ck-content strong {
	color: inherit !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
	transition: 0.3s;
}

button:disabled {
	cursor: not-allowed;
}

a {
	transition: color 0.3s, background 0.3s;
}

.font-medium {
	font-family: 'Aeonik-Medium', sans-serif;
	font-weight: 500;
}

.font-semibold {
	font-family: 'Aeonik-Medium', sans-serif;
	font-weight: 500;
}

.font-bold {
	font-family: 'Aeonik-Bold', sans-serif;
	font-weight: 700;
}

strong {
	color: #111827;
}

p,
a {
	font-size: 14px;
	line-height: 155%;
	font-weight: 400;
}

.sidebar-scroll-not-width::-webkit-scrollbar {
	width: 0 !important;
}

.sidebar-scroll::-webkit-scrollbar {
	width: 4px;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

/* Track */
.sidebar-scroll::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

/* Handle */
.sidebar-scroll::-webkit-scrollbar-thumb {
	background: #1673fa;
	border-radius: 8px;
}

/* Handle on hover */
.sidebar-scroll::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* */

.modal-scroll::-webkit-scrollbar {
	width: 5px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

/* Track */
.modal-scroll::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

/* Handle */
.modal-scroll::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
}

/* Handle on hover */
.modal-scroll::-webkit-scrollbar-thumb:hover {
	background: #555;
}

input[type='date']::-webkit-calendar-picker-indicator {
	display: block;
	background: url(/public/assets/images/form-calendar.png) no-repeat;
	width: 18.5px;
	height: 18.5px;
}
input[type='datetime-local']::-webkit-calendar-picker-indicator {
	display: block;
	background: url(/public/assets/images/form-calendar.png) no-repeat;
	width: 18.5px;
	height: 18.5px;
}

input[type='time']::-webkit-calendar-picker-indicator {
	display: block;
	background: url(/public/assets/images/form-clock.png) no-repeat;
	width: 18.5px;
	height: 18.5px;
}

.link_div {
	display: block;
	border: 1px solid #e2e8f0;
	background: #f7f9fb;
	border-radius: 39px;
	padding: 14px 26px;
	width: 100%;
	margin-top: 8px;
	box-sizing: border-box;
}

.react-select__option:hover {
	background-color: #1673fa;
	color: #ffff;
}

.comment {
	display: inline-block;
	max-width: 300px;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
}

#tooltip {
	right: 30% !important;
}

@media (max-width: 1920px) {
	#tooltip {
		right: 20% !important;
	}
}

.tox-tinymce {
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
	border: 1px solid #d1d5db !important;
}

.form-input {
	appearance: none;
	background-color: #ffffff;
	border-color: #d2d6dc;
	border-width: 1px;
	border-radius: 0.375rem /* 6px */;
	padding-top: 0.5rem /* 8px */;
	padding-right: 0.75rem /* 12px */;
	padding-bottom: 0.5rem /* 8px */;
	padding-left: 0.75rem /* 12px */;
	font-size: 1rem /* 16px */;
	line-height: 1.5;
}

.form-input::placeholder {
	color: #9fa6b2;
	opacity: 1;
}

.form-input:focus {
	outline: none;
	box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
	border-color: #a4cafe;
}

.allow-dropdown {
	width: 100%;
}

td,
th {
	transition: 0.3s;
}

tr.hover:hover th {
	background-color: #f3f4f6;
}

tr.hover:hover td {
	background-color: #f3f4f6;
}

.tox .tox-editor-header{
	z-index: 0 !important;
}

.comments-not-read {
	height: 20px;
	padding: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #FF6B6B;
    font-size: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #FFF;
    font-weight: 700;
}

.error-textBox .tox-tinymce{
	border-color: red !important;
}

.react-datepicker__year-wrapper{
    max-width: 204px !important;
}

canvas{
	width: 100% !important;
	max-height: 338px;
}
